<template>
  <div class="main">
    <div class="title">隐私权政策</div>
    <AgreementYS />
  </div>
</template>

<script>
import AgreementYS from "@/components/AgreementYS";
export default {
  data() {
    return {};
  },

  components: { AgreementYS },

  computed: {},

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.main {
  .title {
    text-align: center;
    font-size: 0.4rem;
    height: 0.6rem;
    line-height: 0.6rem;
    margin: 0.4rem;
  }
  ::v-deep .wrapper {
    height: 100%;
  }
}
</style>
